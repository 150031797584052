<template>
  <div
    class="disclaimer"
  >
    {{ t('subtitle', { company: companyName }) }}
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { rvIsFromCanada, rvState } = useRvDetails()

const companyName = computed(() => {
  if (rvIsFromCanada.value) {
    return rvState.value === lookupIsoCodeToState('BC') ? 'ICBC' : 'Liberty Mutual Insurance'
  }
  return 'Crum and Forster'
})
</script>

<style lang="scss" scoped>
.disclaimer {
  color: getColor('primary-350');
  @include caption;
}
</style>

<i18n lang="json">
{
  "en": {
    "subtitle": "Insurance provided by {company}."
  },
  "fr": {
    "subtitle": "Assurance fournie par {company}."
  }
}
</i18n>
