<template>
  <div
    v-if="canShowForeignCurrencyDisclaimer"
    class="disclaimer"
  >
    {{ t('disclaimer') }}
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const { isInCa } = useGeolocation()
const { rvIsFromCanada, rvIsFromUs } = useRvDetails()

const rvIsCanadaRules = computed(() => rvIsFromCanada.value && !isInCa.value)
const rvIsFromUsRules = computed(() => rvIsFromUs.value && isInCa.value) // unidentified users or from somewhere else than canada will not get the foreign currency warning

const canShowForeignCurrencyDisclaimer = computed(() => rvIsCanadaRules.value || rvIsFromUsRules.value)
</script>

<style lang="scss" scoped>
.disclaimer {
  font-size: 11px;
  color: getColor('primary-200');
}
</style>

<i18n lang="json">
{
  "en": {
    "disclaimer": "You will be charged in this listing's local currency. A foreign exchange rate and fees may be applied by your card issuer at the time of billing."
  },
  "fr": {
    "disclaimer": "Vous serez facturé dans la devise locale de cette annonce. Un taux de change et des frais peuvent être appliqués par l'émetteur de votre carte au moment de la facturation."
  }
}
</i18n>
